import React, { useEffect } from 'react';

import { styled } from '@this/constants/themes';
import { Link } from '@this/shared/ui/navigations/link';
import { Flex } from '@this/shared/ui/layout/flex';
import Notification from '@this/src/notification';
import { Modal, ModalHeader, ModalBody } from '@this/shared/ui/feedbacks/modal';
import type Trip from '@this/domain/trip/trip';
import type OrderItem from '@this/domain/order_item';
import type TransportElement from '@this/domain/transport_element';
import type TransportElementAttachFile from '@this/domain/transport_element_attach_file';

type Props = {
  className?: string;
  onChange: (files: Attachment[]) => void;
  selectedTrip: Trip | null;
};

type Attachment = {
  id: string;
  file: File | null;
  isRegistered: boolean;
};

// 4桁のランダムなIDを生成。
const createId = () => {
  const max = 9999;
  const min = 1000;
  return `temp-${Math.floor(Math.random() * (max + 1 - min)) + min}`;
};

export const AttachmentsField = ({ className, onChange, selectedTrip }: Props) => {
  const [attachments, setAttachments] = React.useState<Attachment[]>([]);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [registeredFiles, setRegisteredFiles] = React.useState<File[]>([]);

  useEffect(() => {
    if (selectedTrip) {
      const files = selectedTrip.order.orderItems
        ?.flatMap((orderItem: OrderItem) => orderItem.elements as TransportElement[])
        .flatMap((transport: TransportElement) => transport.transportElementAttachFile || [])
        .filter(Boolean)
        .map(
          (fileData: TransportElementAttachFile) =>
            new File([fileData.filePath], fileData.uploadFileName, { type: fileData.contentType })
        );
      setRegisteredFiles(files || []);
    }
  }, [selectedTrip]);

  const changeAttachments = React.useCallback(
    (newAttachments: Attachment[]) => {
      setAttachments([...newAttachments]);
      onChange(newAttachments.filter(a => !!a.file) as Attachment[]);
    },
    [setAttachments, onChange]
  );

  const handleClickAddAttachment = React.useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      setAttachments([...attachments, { id: createId(), file: null, isRegistered: false }]);
    },
    [setAttachments, attachments]
  );

  const handleInputChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, id: Attachment['id']) => {
      const newAttachments = [...attachments];
      const target = newAttachments.find(a => a.id === id);
      if (target) {
        target.file = (e.target?.files ?? [])[0];
        if (target.file.size > 10 * 1024 * 1024) {
          const errorMessage = 'ファイルサイズが大きすぎます。10MB以下のファイルを選択してください。';
          target.file = null;
          e.target.value = '';
          Notification.error(errorMessage);
          return;
        }
      }
      changeAttachments(newAttachments);
    },
    [attachments, changeAttachments]
  );

  const handleClickRemoveAttachment = React.useCallback(
    (id: Attachment['id']) => {
      const newAttachments = [...attachments];
      const index = newAttachments.findIndex(a => a.id === id);
      if (index !== -1) {
        newAttachments.splice(index, 1);
      }
      changeAttachments(newAttachments);
    },
    [attachments, changeAttachments]
  );

  const handleClickSelectRegisteredFile = React.useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      setIsModalOpen(true);
    },
    [registeredFiles]
  );

  const handleSelectFileFromModal = React.useCallback(
    (file: File) => {
      setIsModalOpen(false);
      // 右ナビ登録時にチェックしているため、ファイルサイズはチェックしない
      setAttachments([...attachments, { id: createId(), file, isRegistered: true }]);
      changeAttachments([...attachments, { id: createId(), file, isRegistered: true }]);
    },
    [attachments, changeAttachments]
  );

  return (
    <Root className={className}>
      <AddAttachmentButton size="small" onClick={handleClickAddAttachment} data-testid="attachment-field-add">
        添付ファイルを追加
      </AddAttachmentButton>
      <div>
        <SelectRegisteredFileButton size="small" onClick={handleClickSelectRegisteredFile}>
          登録済みファイルを選択
        </SelectRegisteredFileButton>
      </div>
      <ul>
        {attachments.map(attachment => (
          <ListItem key={attachment.id}>
            <Flex justifyContent="space-between" alignItems="center">
              {attachment.isRegistered ? (
                <span>登録済みファイルより選択したファイル: {attachment.file?.name}</span>
              ) : (
                <input
                  type="file"
                  name="files[]"
                  onChange={e => handleInputChange(e, attachment.id)}
                  accept="image/jpeg, image/gif, image/png, application/pdf"
                  data-testid="attachment-field-input"
                />
              )}
              <Link size="small" onClick={() => handleClickRemoveAttachment(attachment.id)}>
                削除
              </Link>
            </Flex>
          </ListItem>
        ))}
      </ul>
      {/* modal */}
      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <ModalHeader>登録済みファイルの選択</ModalHeader>
        <ModalBody>
          <ul>
            {registeredFiles.map((file, index) => {
              const isFileSelected = attachments.some(att => att.file?.name === file.name);
              return (
                <ListItem key={index}>
                  <Link
                    size="small"
                    onClick={() => handleSelectFileFromModal(file)}
                    disabled={isFileSelected}
                    style={{
                      pointerEvents: isFileSelected ? 'none' : 'auto',
                      fontSize: '12px',
                      ...(isFileSelected && { color: 'gray' })
                    }}
                  >
                    {file.name} {isFileSelected && '(選択済み)'}
                  </Link>
                </ListItem>
              );
            })}
          </ul>
          {registeredFiles.length === 0 && <p>登録済みファイルなし</p>}
        </ModalBody>
      </Modal>
    </Root>
  );
};

const Root = styled.div``;

const AddAttachmentButton = styled(Link)`
  margin-bottom: 8px;
`;

const ListItem = styled.li`
  margin-left: 8px;
`;

const SelectRegisteredFileButton = styled(Link)`
  margin-bottom: 8px;
`;
